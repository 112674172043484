import { Routes, Route } from 'react-router-dom'
import { LayoutSimple } from '../views/LayoutSimple'

export const MainRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<LayoutSimple />} />
    </Routes>
  )
}
