import { Layout } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

import { LogIn } from './LogIn'
import { MainRouter } from '../Components/MainRouter'
import { Header } from '../Components/Header'

export const Main = () => {
  const auth = useAuth()
  const { isAuthenticated } = auth

  if (isAuthenticated()) {
    return (
      // ATTENTION: Canvas v7 - Component "Layout" now accepts prop theme={ "default" | "target" | "blue" | "grey" | "green" | "roundel" }
      // ATTENTION: Canvas v7 - Component "Layout" now accepts prop darkMode={ true | false } - Specify darkmode to override the client browser's preferences. Applications previously using the "First Avenue" theme should set darkMode={true}.
      <Layout data-testid="mainAuthenticatedLayout" theme="target">
        <Header />
        <MainRouter />
      </Layout>
    )
  } else {
    return <LogIn />
  }
}
