/* eslint-disable prettier/prettier */
import { Helmet } from 'react-helmet'
import { Grid, Form, Chip, Input, Button } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { useFormik } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import apiConfig from '../Config/apiConfig'
import { stateOptions } from './util/Miscellaneous'
import { Loader } from './Loader'
import { SimpleDialog } from './SimpleDialog'
import PropTypes from 'prop-types'
import './Styles.scss'
import { useState } from 'react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'

SimpleDialog.propTypes = {
  handleClose: PropTypes.func.handleClose,
  open: PropTypes.bool.isRequired,
  textmessage: PropTypes.string.isRequired,
}

export const DocumentRequest = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  let modalDescription = 'Your request has been successfully submitted'

  const deliveryTypeOptions = [
    { label: 'Select Delivery Type', value: '' },
    { label: 'Secure Email', value: 'Secure Email' },
    { label: 'US Mail', value: 'US Mail' },
  ]

  const requesterTypeOptions = [
    { label: 'Select Requester Type', value: '' },
    { label: 'Target Team Member / HR', value: 'teamMemberHR' },
    { label: 'ER / Legal Requests / Target Partners', value: 'partners' },
  ]

  const onChangeChecked = (event, vall) => {
    if (event.target.checked) {
      formik.values.docs_requested.push(vall)
    } else if (event.target.checked === false) {
      formik.values.docs_requested.pop(vall)
    }
  }

  const auth = useAuth()

  const formik = useFormik({
    initialValues: {
      requestorId: auth.session.userInfo.lanId,
      requestorFullName: auth.session.userInfo.fullName,
      requestorEmailId:
        auth.session.userInfo.email !== null ||
        auth.session.userInfo.email !== '' ||
        auth.session.userInfo.email !== 'undefined'
          ? auth.session.userInfo.email
          : '',
      requestorType: requesterTypeOptions[0].value,
      deliveryType: deliveryTypeOptions[0].value,
      deliveryEmail: '',
      deliveryAddress: '',
      deliveryAddressLine1: '',
      deliveryAddressLine2: '',
      deliveryAddressCity: '',
      deliveryAddressState: '',
      deliveryAddressZip: '',
      teamMemberId: '',
      teamMemberName: '',
      workLocationState: '',
      subPeonaPartners: '',
      wwrfrom: '',
      wwrto: '',
      ckdrfrom: '',
      ckdrto: '',
      pdrptfrom: '',
      pdrptto: '',
      adrfrom: '',
      adrto: '',
      strstfrom: '',
      strstto: '',
      psrfrom: '',
      psrto: '',
      weekly_wage: false,
      check_detail: false,
      punch_detail: false,
      attendance_details_report: false,
      store_roster: false,
      payslip_range: false,
      other_documents: false,
      otherDocDetails: '',
      docs_requested: [],
    },
    validationSchema: Yup.object({
      requestorEmailId: Yup.string().email().required('Required'),
      deliveryType: Yup.string()
        .required('Required')
        .oneOf(['1', '2'], 'Select a value'),
      teamMemberId: Yup.string().required('Required'),
      teamMemberName: Yup.string().required('Required'),
      requestorType: Yup.string()
        .required('Required')
        .oneOf(['1', '2'], 'Select a value'),
      workLocationState: Yup.string().required('Required'),
      // docs_requested: Yup.array().min(1).required('Required'),
      deliveryEmail: Yup.string().when('deliveryType', {
        is: '1',
        then: () => Yup.string().required('Required'),
        otherwise: () => Yup.string(),
      }),
      deliveryAddress: Yup.string().when('deliveryType', {
        is: '2',
        then: () => Yup.string().required('Required'),
        otherwise: () => Yup.string(),
      }),
      deliveryAddressLine1: Yup.string().when('deliveryType', {
        is: '2',
        then: () => Yup.string().required('Required'),
        otherwise: () => Yup.string(),
      }),
      deliveryAddressCity: Yup.string().when('deliveryType', {
        is: '2',
        then: () => Yup.string().required('Required'),
        otherwise: () => Yup.string(),
      }),
      deliveryAddressZip: Yup.string().when('deliveryType', {
        is: '2',
        then: () => Yup.string().required('Required'),
        otherwise: () => Yup.string(),
      }),
      deliveryAddressState: Yup.string().when('deliveryType', {
        is: '2',
        then: () => Yup.string().required('Required'),
        otherwise: () => Yup.string(),
      }),
      weekly_wage: Yup.boolean(),
      check_detail: Yup.boolean(),
      punch_detail: Yup.boolean(),
      attendance_details_report: Yup.boolean(),
      store_roster: Yup.boolean(),
      payslip_range: Yup.boolean(),
      other_documents: Yup.boolean(),
      wwrfrom: Yup.date().when('weekly_wage', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      wwrto: Yup.date().when('weekly_wage', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      ckdrfrom: Yup.date().when('check_detail', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      ckdrto: Yup.date().when('check_detail', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      pdrptfrom: Yup.date().when('punch_detail', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      pdrptto: Yup.date().when('punch_detail', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      adrfrom: Yup.date().when('attendance_details_report', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      adrto: Yup.date().when('attendance_details_report', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      strstfrom: Yup.date().when('store_roster', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      strstto: Yup.date().when('store_roster', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      psrfrom: Yup.date().when('payslip_range', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      psrto: Yup.date().when('payslip_range', {
        is: true,
        then: () => Yup.date().required('Required'),
        otherwise: () => Yup.date(),
      }),
      otherDocDetails: Yup.string().when('other_documents', {
        is: true,
        then: () => Yup.string().required('Required'),
        otherwise: () => Yup.string(),
      }),
    }),

    onSubmit: (values, { resetForm }) => {
      //turn on loader
      setIsLoading(!isLoading)

      const requestBody = {
        requester_id: values.requestorId,
        requester_name: values.requestorFullName,
        requester_email: values.requestorEmailId,
        requester_type: requesterTypeOptions[values.requestorType].label,
        docs_requested: [],
        delivery_type: deliveryTypeOptions[values.deliveryType].label,
        delivery_address:
          values.deliveryType === '1'
            ? values.deliveryEmail
            : values.deliveryAddress +
              ', ' +
              values.deliveryAddressLine1 +
              ', ' +
              values.deliveryAddressLine2 +
              ', ' +
              values.deliveryAddressCity +
              ', ' +
              stateOptions[values.deliveryAddressState].value +
              ', ' +
              values.deliveryAddressZip,
        teammember_id: values.teamMemberId,
        teammember_name: values.teamMemberName,
        work_state: stateOptions[values.workLocationState].value,
        subpoena_partner: values.subPeonaPartners,
        documents: {
          document_list: values.docs_requested,
          weekly_wage_report: values.weekly_wage
            ? [values.wwrfrom, values.wwrto]
            : null,
          punch_detail_report: values.punch_detail
            ? [values.pdrptfrom, values.pdrptto]
            : null,
          attendance_details_report: values.attendance_details_report
            ? [values.adrfrom, values.adrto]
            : null,
          store_roster_report: values.store_roster
            ? [values.strstfrom, values.strstto]
            : null,
          check_detail_report: values.check_detail
            ? [values.ckdrfrom, values.ckdrto]
            : null,
          payslip_range_report: values.payslip_range
            ? [values.psrfrom, values.psrto]
            : null,
          other_document: values.other_documents
            ? values.otherDocDetails
            : null,
        },
      }

      const requestConfig = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          'x-api-key': apiConfig.docrequest.key,
        },
      }

      axios
        .post(
          apiConfig.docrequest.submitUrl + '?key=' + apiConfig.docrequest.key,
          requestBody,
          requestConfig
        )
        .then((response) => {
          if (response) {
            if (response.status === 200 || response.status === 202) {
              setIsLoading(false)
              setOpenModal(true)
            }
          }
        })
    },
  })

  const handleClose = (value) => {
    setOpenModal(false)
    window.location.reload()
  }

  if (isLoading) {
    return (
      <div className="LoaderStyle">
        <Loader />
      </div>
    )
  } else {
    return (
      <Form
        id="document-request"
        className="hc-ma-expanded hc-ml-expanded hc-pl-expanded"
        onSubmit={formik.handleSubmit}
      >
        <div className="hc-pa-normal">
          <Helmet
            defaultTitle="HROC Document Request"
            requestorTitleTemplate=""
          />
          <Grid.Container className="Margin All">
            <Grid.Container>
              <Grid.Item xs={9}>
                <Input.Label>
                  Personnel documents are retained at Target's Human Resource
                  Operations Center and copies may be obtained by completing
                  this request. All requests will be fulfilled following
                  Target's Documentation release guidelines.
                </Input.Label>
              </Grid.Item>
              <Grid.Container className="hc-pb-expanded">
                <Grid.Item xs={12}>
                  <Chip>REQUESTOR INFORMATION</Chip>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container xs={9}>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="requestorId"
                    name="requestorId"
                    label="Requestor ID"
                    required={true}
                    placeholder="Enter your Id."
                    value={auth.session.userInfo.lanId}
                    onChange={formik.handleChange}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="requestorFullName"
                    name="requestorFullName"
                    label="Full Name"
                    required={true}
                    placeholder="Requestor Name"
                    value={auth.session.userInfo.fullName}
                    onChange={formik.handleChange}
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container xs={12}>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="requestorEmailId"
                    name="requestorEmailId"
                    label="Email ID"
                    type="email"
                    required={true}
                    placeholder="Enter your Email Id."
                    defaultValue={auth.session.userInfo.email}
                    onChange={formik.handleChange}
                    error={
                      formik.errors.requestorEmailId &&
                      formik.touched.requestorEmailId
                    }
                    errorText={formik.errors.requestorEmailId}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="requestorType"
                    name="requestorType"
                    type="select"
                    label="Requestor Type"
                    defaultValue=""
                    required
                    options={requesterTypeOptions}
                    onChange={formik.handleChange}
                    error={
                      formik.errors.requestorType &&
                      formik.touched.requestorType
                    }
                    errorText={formik.errors.requestorType}
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container xs={12}>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="deliveryType"
                    type="select"
                    name="deliveryType"
                    label="Delivery Method"
                    defaultValue=""
                    required
                    options={deliveryTypeOptions}
                    onChange={formik.handleChange}
                    error={
                      formik.errors.deliveryType && formik.touched.deliveryType
                    }
                    errorText={formik.errors.deliveryType}
                  />
                </Grid.Item>
                {formik.values.deliveryType === '1' ? (
                  <Grid.Item xs={4}>
                    <Form.Field
                      id="deliveryEmail"
                      name="deliveryEmail"
                      label="Secure Email ID"
                      required={true}
                      placeholder="Enter Email for delivery"
                      onChange={formik.handleChange}
                      error={
                        formik.errors.deliveryEmail &&
                        formik.touched.deliveryEmail
                      }
                      errorText={formik.errors.deliveryEmail}
                    />
                  </Grid.Item>
                ) : null}
                {formik.values.deliveryType === '2' ? (
                  <Grid.Item xs={7}>
                    <Grid.Container>
                      <Grid.Item xs={7}>
                        <Form.Field
                          id="deliveryAddress"
                          name="deliveryAddress"
                          label="Delivery Address"
                          required
                          placeholder="Name/Organization"
                          onChange={formik.handleChange}
                          error={
                            formik.errors.deliveryAddress &&
                            formik.touched.deliveryAddress
                          }
                          errorText={formik.errors.deliveryAddress}
                        />
                      </Grid.Item>
                    </Grid.Container>
                    <Grid.Container>
                      <Grid.Item xs={7}>
                        <Form.Field
                          id="deliveryAddressLine1"
                          name="deliveryAddressLine1"
                          required={true}
                          placeholder="Address Line 1"
                          onChange={formik.handleChange}
                          error={
                            formik.errors.deliveryAddressLine1 &&
                            formik.touched.deliveryAddressLine1
                          }
                          errorText={formik.errors.deliveryAddressLine1}
                        />
                      </Grid.Item>
                    </Grid.Container>
                    <Grid.Container>
                      <Grid.Item xs={7}>
                        <Form.Field
                          id="deliveryAddressLine2"
                          name="deliveryAddressLine2"
                          required={true}
                          placeholder="Address Line 2"
                          onChange={formik.handleChange}
                        />
                      </Grid.Item>
                    </Grid.Container>
                    <Grid.Container>
                      <Grid.Item xs={3}>
                        <Form.Field
                          id="deliveryAddressCity"
                          name="deliveryAddressCity"
                          placeholder="City"
                          onChange={formik.handleChange}
                          error={
                            formik.errors.deliveryAddressCity &&
                            formik.touched.deliveryAddressCity
                          }
                          errorText={formik.errors.deliveryAddressCity}
                        />
                      </Grid.Item>
                      <Grid.Item xs={3}>
                        <Input.Select
                          id="deliveryAddressState"
                          name="deliveryAddressState"
                          type="select"
                          defaultValue=""
                          required={true}
                          options={stateOptions}
                          onChange={formik.handleChange}
                          error={
                            formik.errors.deliveryAddressState &&
                            formik.touched.deliveryAddressState
                          }
                          errorText={formik.errors.deliveryAddressState}
                        />
                      </Grid.Item>
                      <Grid.Item xs={3}>
                        <Form.Field
                          id="deliveryAddressZip"
                          name="deliveryAddressZip"
                          required={true}
                          placeholder="Zip Code"
                          onChange={formik.handleChange}
                          error={
                            formik.errors.deliveryAddressZip &&
                            formik.touched.deliveryAddressZip
                          }
                          errorText={formik.errors.deliveryAddressZip}
                        />
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                ) : null}
              </Grid.Container>
              <Grid.Container className="hc-pb-expanded">
                <Grid.Item xs={12}>
                  <Chip>TEAM MEMBER INFORMATION</Chip>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container xs={6}>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="teamMemberId"
                    name="teamMemberId"
                    label="Team Member ID"
                    required
                    placeholder="Emp ID of Document Request"
                    onChange={formik.handleChange}
                    error={
                      formik.errors.teamMemberId && formik.touched.teamMemberId
                    }
                    teamMemberId
                    errorText={formik.errors.teamMemberId}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="teamMemberName"
                    name="teamMemberName"
                    label="Team Member Name"
                    required
                    error={
                      formik.errors.teamMemberName &&
                      formik.touched.teamMemberName
                    }
                    errorText={formik.errors.teamMemberName}
                    placeholder="Emp Name of Document Request"
                    onChange={formik.handleChange}
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container xs={6}>
                <Grid.Item xs={4}>
                  <Input.Label>Work Location State*</Input.Label>
                  <Input.Select
                    id="workLocationState"
                    name="workLocationState"
                    type="select"
                    defaultValue=""
                    onChange={formik.handleChange}
                    options={stateOptions}
                    error={
                      formik.errors.workLocationState &&
                      formik.touched.workLocationState
                    }
                    errorText={formik.errors.workLocationState}
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container className="hc-pb-expanded">
                <Grid.Item>
                  <Input.Label />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Chip>DOCUMENT INFORMATION</Chip>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item>
                  <Input.Label
                    error={formik.errors.docs_requested}
                    errorText={formik.errors.docs_requested}
                    required
                  >
                    Please check all the boxes that apply
                  </Input.Label>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item xs={1}>
                  <Input.Label />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Input.Checkbox
                    id="tmpsnl"
                    label="Team Member Personnel File"
                    onChange={(event) =>
                      onChangeChecked(event, 'Team Member Personnel File')
                    }
                  />
                  <Input.Checkbox
                    id="tmvr"
                    label="Team Member Verification Report"
                    onChange={(event) =>
                      onChangeChecked(event, 'Team Member Verification Report')
                    }
                  />
                  <Input.Checkbox
                    id="jobapp"
                    label="Job Application"
                    onChange={(event) =>
                      onChangeChecked(event, 'Job Application')
                    }
                  />
                  <Input.Checkbox
                    id="orpw"
                    label="Orientation Paperwork"
                    onChange={(event) =>
                      onChangeChecked(event, 'Orientation Paper Work')
                    }
                  />
                  <Input.Checkbox
                    id="wahf"
                    label="Wage & Hour Forms"
                    onChange={(event) =>
                      onChangeChecked(event, 'Wage & Hour Forms')
                    }
                  />
                  <Input.Checkbox
                    id="Screen"
                    label="Screening Documents"
                    onChange={(event) =>
                      onChangeChecked(event, 'Screening Documents')
                    }
                  />
                  <Input.Checkbox
                    id="appapr"
                    label="Review (Annual, 90 Day)"
                    onChange={(event) =>
                      onChangeChecked(event, 'Review (Annual,90 Day)')
                    }
                  />
                  <Input.Checkbox
                    id="corAction"
                    label="Corrective Actions"
                    onChange={(event) =>
                      onChangeChecked(event, 'Corrective Actions')
                    }
                  />
                  <Input.Checkbox
                    id="TermDoc"
                    label="Termination Documents"
                    onChange={(event) =>
                      onChangeChecked(event, 'Termination Documents')
                    }
                  />
                  {formik.values.requestorType === '2' ? (
                    <div>
                      <Input.Checkbox
                        id="i9"
                        label="I-9"
                        onChange={(event) =>
                          onChangeChecked(event, 'I-9 Documents')
                        }
                      />
                      <Input.Checkbox
                        id="w2"
                        label="W-2"
                        onChange={(event) =>
                          onChangeChecked(event, 'W-2 Documents')
                        }
                      />
                      <Input.Checkbox
                        id="w4"
                        label="W-4"
                        onChange={(event) =>
                          onChangeChecked(event, 'W-4 Documents')
                        }
                      />

                      <Input.Checkbox
                        id="LearningHistory"
                        label="Learning History"
                        onChange={(event) =>
                          onChangeChecked(event, 'Learning History')
                        }
                      />
                    </div>
                  ) : null}
                  <Input.Checkbox
                    id="wwagerpt"
                    name="wwagerpt"
                    label="Weekly Wage Report (Indicate date ranges below)"
                    onChange={(event) => {
                      formik.setFieldValue(
                        'weekly_wage',
                        !formik.values.weekly_wage
                      )
                    }}
                  />
                  {formik.values.weekly_wage ? (
                    <Grid.Container>
                      <Grid.Item xs={6}>
                        <DatePicker
                          id="wwrfrom"
                          name="wwrfrom"
                          error={
                            formik.errors.wwrfrom && formik.touched.wwrfrom
                          }
                          errorText={formik.errors.wwrfrom}
                          label="From"
                          onUpdate={(id, value) =>
                            formik.setFieldValue(id, value)
                          }
                        />
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <DatePicker
                          id="wwrto"
                          name="wwrto"
                          error={formik.errors.wwrto && formik.touched.wwrto}
                          errorText={formik.errors.wwrto}
                          label="To"
                          onUpdate={(id, value) =>
                            formik.setFieldValue(id, value)
                          }
                        />
                      </Grid.Item>
                    </Grid.Container>
                  ) : null}
                  <Input.Checkbox
                    id="ckdtrpt"
                    label="Check Detail Report (Indicate date ranges below)"
                    onChange={(event) => {
                      formik.setFieldValue(
                        'check_detail',
                        !formik.values.check_detail
                      )
                      // onChangeChecked(event, 'Check Detail Report')
                    }}
                  />
                  {formik.values.check_detail ? (
                    <Grid.Container>
                      <Grid.Item xs={6}>
                        <DatePicker
                          id="ckdrfrom"
                          name="ckdrfrom"
                          label="From"
                          onUpdate={(id, value) =>
                            formik.setFieldValue(id, value)
                          }
                          error={
                            formik.errors.ckdrfrom && formik.touched.ckdrfrom
                          }
                          errorText={formik.errors.ckdrfrom}
                        />
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <DatePicker
                          id="ckdrto"
                          name="ckdrto"
                          label="To"
                          onUpdate={(id, value) =>
                            formik.setFieldValue(id, value)
                          }
                          error={formik.errors.ckdrto && formik.touched.ckdrto}
                          errorText={formik.errors.ckdrto}
                        />
                      </Grid.Item>
                    </Grid.Container>
                  ) : null}

                  <Input.Checkbox
                    id="pdrpt"
                    label="Punch Detail Report (Indicate date ranges below)"
                    onChange={(event) => {
                      formik.setFieldValue(
                        'punch_detail',
                        !formik.values.punch_detail
                      )
                    }}
                  />

                  {formik.values.punch_detail ? (
                    <Grid.Container>
                      <Grid.Item xs={6}>
                        <DatePicker
                          id="pdrptfrom"
                          name="pdrptfrom"
                          label="From"
                          onUpdate={(id, value) =>
                            formik.setFieldValue(id, value)
                          }
                          error={
                            formik.errors.pdrptfrom && formik.touched.pdrptfrom
                          }
                          errorText={formik.errors.pdrptfrom}
                        />
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <DatePicker
                          id="pdrptto"
                          name="pdrptto"
                          label="To"
                          onUpdate={(id, value) =>
                            formik.setFieldValue(id, value)
                          }
                          error={
                            formik.errors.pdrptto && formik.touched.pdrptto
                          }
                          errorText={formik.errors.pdrptto}
                        />
                      </Grid.Item>
                    </Grid.Container>
                  ) : null}

                  {formik.values.requestorType === '2' ? (
                    <div>
                      <Input.Checkbox
                        id="attendanceDetailsReport"
                        label="Attendance Detail Report (Indicate date ranges below)"
                        onChange={(event) => {
                          formik.setFieldValue(
                            'attendance_details_report',
                            !formik.values.attendance_details_report
                          )
                        }}
                      />
                    </div>
                  ) : null}

                  {formik.values.attendance_details_report ? (
                    <Grid.Container>
                      <Grid.Item xs={6}>
                        <DatePicker
                          id="adrfrom"
                          name="adrfrom"
                          label="From"
                          onUpdate={(id, value) =>
                            formik.setFieldValue(id, value)
                          }
                          error={
                            formik.errors.adrfrom && formik.touched.adrfrom
                          }
                          errorText={formik.errors.adrfrom}
                        />
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <DatePicker
                          id="adrto"
                          name="adrto"
                          label="To"
                          onUpdate={(id, value) =>
                            formik.setFieldValue(id, value)
                          }
                          error={formik.errors.adrto && formik.touched.adrto}
                          errorText={formik.errors.adrto}
                        />
                      </Grid.Item>
                    </Grid.Container>
                  ) : null}
                  {formik.values.requestorType === '2' ? (
                    <Grid.Item>
                      <Input.Checkbox
                        id="strst"
                        label="Store Roster (Indicate date ranges below)"
                        onChange={(event) => {
                          formik.setFieldValue(
                            'store_roster',
                            !formik.values.store_roster
                          )
                          // onChangeChecked(event, 'Store Roster Documents')
                        }}
                      />

                      {formik.values.store_roster ? (
                        <Grid.Container>
                          <Grid.Item xs={6}>
                            <DatePicker
                              id="strstfrom"
                              name="strstfrom"
                              label="From"
                              onUpdate={(id, value) =>
                                formik.setFieldValue(id, value)
                              }
                              error={
                                formik.errors.strstfrom &&
                                formik.touched.strstfrom
                              }
                              errorText={formik.errors.strstfrom}
                            />
                          </Grid.Item>
                          <Grid.Item xs={6}>
                            <DatePicker
                              id="strstto"
                              name="strstto"
                              label="To"
                              onUpdate={(id, value) =>
                                formik.setFieldValue(id, value)
                              }
                              error={
                                formik.errors.strstto && formik.touched.strstto
                              }
                              errorText={formik.errors.strstto}
                            />
                          </Grid.Item>
                        </Grid.Container>
                      ) : null}
                      <Input.Checkbox
                        id="Payslip"
                        label="Pay Slips (Indicate date ranges below)"
                        onChange={(event) => {
                          formik.setFieldValue(
                            'payslip_range',
                            !formik.values.payslip_range
                          )
                          // onChangeChecked(event, 'Pay Slips')
                        }}
                      />
                      {formik.values.payslip_range ? (
                        <Grid.Container>
                          <Grid.Item xs={6}>
                            <DatePicker
                              id="psrfrom"
                              name="psrfrom"
                              label="From"
                              onUpdate={(id, value) =>
                                formik.setFieldValue(id, value)
                              }
                              error={
                                formik.errors.psrfrom && formik.touched.psrfrom
                              }
                              errorText={formik.errors.psrfrom}
                            />
                          </Grid.Item>
                          <Grid.Item xs={6}>
                            <DatePicker
                              id="psrto"
                              name="psrto"
                              label="To"
                              onUpdate={(id, value) =>
                                formik.setFieldValue(id, value)
                              }
                              error={
                                formik.errors.psrto && formik.touched.psrto
                              }
                              errorText={formik.errors.psrto}
                            />
                          </Grid.Item>
                        </Grid.Container>
                      ) : null}
                      <Input.Checkbox
                        id="othrs"
                        label="Other TM Document (Indicate below)"
                        onChange={(event) => {
                          formik.setFieldValue(
                            'other_documents',
                            !formik.values.other_documents
                          )
                          onChangeChecked(event, 'Other Documents')
                        }}
                      />
                      {formik.values.other_documents ? (
                        <Grid.Container>
                          <Grid.Item xs={5}>
                            <Form.Field
                              id="otherDocDetails"
                              name="otherDocDetails"
                              required={true}
                              placeholder="Document Details"
                              onChange={formik.handleChange}
                              error={
                                formik.errors.otherDocDetails &&
                                formik.touched.otherDocDetails
                              }
                              errorText={formik.errors.otherDocDetails}
                            />
                          </Grid.Item>
                        </Grid.Container>
                      ) : null}
                    </Grid.Item>
                  ) : null}
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                {formik.values.requestorType === '2' ? (
                  <Grid.Item xs={4}>
                    <Form.Field
                      id="subPeonaPartners"
                      label="Additional Subpoena Partners"
                      name="subPeonaPartners"
                      placeholder="Internal HROC only"
                      onChange={formik.handleChange}
                    />
                  </Grid.Item>
                ) : null}
              </Grid.Container>
              <div className="hc-pa-normal hc-pt-normal">
                <Input.Label align="left">
                  Note<sup>*</sup>: The requestor will receive a copy of the
                  submission upon clicking Submit button.
                </Input.Label>
                <Grid.Container>
                  <Grid.Item xs={1.5}>
                    <Button
                      data-test="Next"
                      type="submit"
                      disabled={!formik?.dirty}
                      onClick={formik.handleSubmit}
                    >
                      Submit
                    </Button>
                    <SimpleDialog
                      open={openModal}
                      onClose={handleClose}
                      headingText={modalDescription}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1.5}>
                    <Button
                      data-test="Next"
                      type="primary"
                      onClick={() => window.location.reload()}
                    >
                      Clear
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </Grid.Container>
          </Grid.Container>
        </div>
      </Form>
    )
  }
}

export default DocumentRequest
