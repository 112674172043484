import { BrowserRouter as Router } from 'react-router-dom'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { AuthProvider } from '@praxis/component-auth'
import './Styles/customStyles.scss'
import { Main } from './views/Main'
import apiConfig from './Config/apiConfig'

const App = () => {
  return (
    <>
      <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
      <Router>
        <AuthProvider {...apiConfig.auth}>
          <Main />
        </AuthProvider>
      </Router>
    </>
  )
}

export default App
