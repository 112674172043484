import {
  Avatar,
  Dropdown,
  Grid,
  Layout,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

const getInitials = (userInfo) =>
  `${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`

export const Header = ({ onSideNavToggle }) => {
  const auth = useAuth()
  const { logout, session } = auth
  return (
    <>
      <Layout.Header className="hc-pl-expanded">
        <Grid.Container align="left" spacing="none">
          <Grid.Item>
            {/* TODO: style is to set the color of the heading in dark/light mode
            Try removing to see if it's fixed */}
            <Heading
              className={'hc-ta-center hc-mt-dense'}
              style={{ color: 'var(--canvas-theme-color--text)' }}
              size={2}
            >
              HROC Document Request Form
            </Heading>
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
      <Layout.GlobalActions>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            <Dropdown size="dense" location="bottom-right">
              <Avatar
                style={{ cursor: 'pointer' }}
                aria-label="Avatar for {{session.userInfo}}"
              >
                {getInitials(session.userInfo)}
              </Avatar>
              <Dropdown.Menu>
                <Dropdown.MenuItem
                  data-testid="headerLogoutButton"
                  onClick={logout}
                >
                  Log Out
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Layout.GlobalActions>
    </>
  )
}
