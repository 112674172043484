import { Layout } from '@enterprise-ui/canvas-ui-react'
import DocumentRequest from '../Components/DocumentRequest'

export const LayoutSimple = () => {
  return (
    <>
      <Layout.Body includeRail data-testid="homePage">
        <DocumentRequest />
      </Layout.Body>
    </>
  )
}
