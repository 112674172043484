import { Dialog } from '@enterprise-ui/canvas-ui-react'
import './Styles.scss'

export function SimpleDialog(props) {
  const { onClose, open, headingText } = props
  const handleClose = () => {
    onClose()
  }
  return (
    <Dialog
      isVisible={open}
      onApprove={handleClose}
      headingText={headingText}
      aria-describedby="alert-dialog-description"
      approveButtonText="Close"
    />
  )
}
